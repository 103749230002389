import React from "react"
import CategoryWrapper from "../../components/CategoryWrapper"

function WomenAccessoriesPage() {
  return (
    <CategoryWrapper
      seoTitle="Accessories"
      gender="dame"
      category="accessories"
    />
  )
}

export default WomenAccessoriesPage
